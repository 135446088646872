<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: "App",
    mounted() {
        // 在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("store")) {
            //this.$store.replaceState是vue官方提供的一个api表示替换 store 的根状态
            //里面的Object.assign()表示将store中的状态和sessionStorage中的状态进行合并
            this.$store.replaceState(Object.assign({}, JSON.parse(sessionStorage.getItem("store")), this.$store.state))
        }
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        // beforeunload表示当浏览器窗口关闭或者刷新时，会触发beforeunload事件
        window.addEventListener("beforeunload", () => {
            sessionStorage.setItem("store", JSON.stringify(this.$store.state))
        })
    },
}
</script>

<style lang="less">
:root {
    --main-bg-color: rgba(2, 57, 70, 1) !important;
    --btn-hover-color: rgba(3, 87, 109, 1) !important;
    // --main-bg-color: rgb(51, 112, 255) !important;
    // --main-bg-color: red!important;
}
/** 检测业务独有样式 */
@import url("./App.less");
</style>

<style lang="less">
.cell {
    & .el-button--text {
        padding-top: 0;
        padding-bottom: 0;
    }
}
html {
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

#app {
    overflow: hidden;
    position: relative;
    font-family: Microsoft YaHei !important;
    width: 100%;
    height: 100%;
    .el-table__body-wrapper {
        .el-button--text,
        .el-button:focus,
        .el-button:hover {
            color: #2d74f4;
        }
    }
}

body,
div,
section,
ul,
li,
a,
aside,
main,
p {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
}

/** 清除内外边距 **/
body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    blockquote,
    // /* structural elements 结构元素 */
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    // /* list elements 列表元素 */
    pre,
    // /* text formatting elements 文本格式元素 */
    form,
    fieldset,
    legend,
    button,
    input,
    textarea,
    // /* form elements 表单元素 */
    th,
    td // /* table elements 表格元素 */
{
    margin: 0;
    padding: 0;
}

/** 设置默认字体 **/
body,
    button,
    input,
    select,
    textarea // /* for ie */
{
    font: 14px/1.5 tahoma, \5b8b\4f53;
    font-family: Microsoft Yahei;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

address,
cite,
dfn,
em,
var {
    font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
    font-family: courier new, courier, monospace;
}

/* 小于 12px 的中文很难阅读, 让 small 正常化 */
/** 重置列表元素 **/
ul,
ol {
    list-style: none;
}

/** 重置文本格式元素 **/
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

sup {
    vertical-align: text-top;
}

/* 重置, 减少对行高的影响 */
sub {
    vertical-align: text-bottom;
}

/** 重置表单元素 **/
legend {
    color: #000;
}

/* for ie6 */
fieldset,
img {
    border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
    font-size: 100%;
}

/* 重置 HTML5 元素 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    display: block;
    margin: 0;
    padding: 0;
}

mark {
    background: #ff0;
}

* {
    box-sizing: border-box;
}
.el-radio {
    margin-bottom: 10px;
}
.lab-dialog__wrapper {
    .lab-dialog {
        // border: unset;
        .el-dialog__header {
            border: none;
            font-size: 20px;
            padding: 30px 15px 10px 22px !important;
            .el-dialog__header-title {
                padding: 10px 0 !important;
            }
        }
    }
}
.custom-class {
    // border: unset;
    .el-dialog__header {
        border: none;
        font-size: 20px;
        font-weight: 600;
        .el-dialog__title {
            font-size: 20px;
        }
    }
    .el-dialog__body {
        padding: 0 20px 30px;
    }
    .dataStandrd_ction {
        .el-button + .el-button,
        .el-checkbox.is-bordered + .el-checkbox.is-bordered {
            margin-left: 0;
        }
    }
}
</style>
